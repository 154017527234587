
import './App.css';
import Desktop from './desktop/Desktop'
import Mobile from './mobile/Mobile'
import DividendPage from './desktop/DividendPage'
import ReferRegister from './desktop/ReferRegister'
import HowToSign from './desktop/HowToSign'
import TkAppDownload from './desktop/TkAppDownload'
import DividendPageMB from './mobile/DividendPageMB'
import ReferRegisterMB from './mobile/ReferRegisterMB'

function App() {
    const path = window.location.pathname
    if (window.innerWidth < 900) {

        if (path.indexOf('dividend') >= 0) {
            return <DividendPageMB/>
        }
        if (path.indexOf('referRegister') >= 0) {
            return <ReferRegisterMB/>
        }
        if (path.indexOf('howToSign') >= 0){
            return <HowToSign/>
        }
        if (path.indexOf('tkAppDownload') >= 0){
            return <TkAppDownload/>
        }
        return (
            <Mobile/>
        );
    } else {

        if (path.indexOf('dividend') >= 0) {
            return <DividendPage/>
        }
        if (path.indexOf('referRegister') >= 0) {
            return <ReferRegister/>
        }
        if (path.indexOf('howToSign') >= 0){
            return <HowToSign/>
        }
        if (path.indexOf('tkAppDownload') >= 0){
            return <TkAppDownload/>
        }
        return (
            <Desktop/>
        );
    }

}

export default App;
