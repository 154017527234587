import './Desktop.css'
import React, {Component} from "react";
import main_logo from "../images/sl_logo.svg";
import nodeIcon from "../images/node.svg";
import customerServiceIcon from "../images/customer_service.svg";
import encryptIcon from "../images/encrypt.svg";
import aiIcon from "../images/ai.svg";
import satIcon from "../images/sat.svg";
import osIcon from "../images/os.svg";
import osWinIcon from "../images/os_win.svg";
import osWinIconFooter from "../images/os_win_footer.svg";
import osAndIcon from "../images/os_and.svg";
import osAndIconFooter from "../images/os_and_footer.svg";
import osIosIcon from "../images/os_ios.svg";
import osIosIconFooter from "../images/os_ios_footer.svg";
import checkCircle from "../images/check_circle.svg";
import osMacosIcon from "../images/os_macos.svg";
import teamIcon from "../images/team.svg";
import telegramIcon from "../images/telegram.svg";
import brandWidthIcon from "../images/brand_width.svg";
import twitterIcon from "../images/twitter.svg";
import phoneImg from "../images/phone1.png";
import user1 from '../images/avatars/user1.png'
import user2 from '../images/avatars/user2.png'
import user3 from '../images/avatars/user3.png'
import user4 from '../images/avatars/user4.png'
import user5 from '../images/avatars/user5.png'
import user6 from '../images/avatars/user6.png'
import user7 from '../images/avatars/user7.png'
import user8 from '../images/avatars/user8.png'
import user9 from '../images/avatars/user9.png'
import user10 from '../images/avatars/user10.png'
import user11 from '../images/avatars/user11.png'
import user12 from '../images/avatars/user12.png'
import user13 from '../images/avatars/user13.png'
import user14 from '../images/avatars/user14.png'
import user15 from '../images/avatars/user15.png'
import user16 from '../images/avatars/user16.png'
import user17 from '../images/avatars/user17.png'
import user18 from '../images/avatars/user18.png'
import user19 from '../images/avatars/user19.png'
import user20 from '../images/avatars/user20.png'
import appLogo from '../images/app_logo.png'
import arrowLeft from '../images/arrow_left.svg'
import {
    getClientId,
    getUrlId,
    getUrlType,
    getUrlWithoutParam,
    hideLoading,
    MainMenuType,
    showLoading,
    showToast,
    startDownload,
    getAndroidDownloadLink
} from "../utils/methods"
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/scss/navigation';

import RegisterModal from "../components/RegisterModal";
import IosDownloadModal from "../components/IosDownloadModal";
import SelectPaymentMethodModal from "../components/SelectPaymentMethodModal";
import LoginModal from "../components/LoginModal";
import ConfirmModal from "../components/ConfirmModal";
import DividendForm from "../components/DividendForm";
import axios from "axios";
import {serverUrl, vcodeCountdown} from "../utils/constants";

const moment = require("moment");


class Desktop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatarList: [],
            currentActiveSlideIndex: 0,
            activeMenu: MainMenuType.Home,
            showRegisterModal: false,
            showIosDownloadModal: false,
            showSelectPaymentMethodModal: false,
            showLoginModal: false,
            vcodeCountdownRemain: 0,
            loginModalTitle: null,
            afterLoginAction: null,
            confirmModalTitle: null,
            confirmModalContent: null,
            showConfirmModal: false
        }
    }

    componentDidMount() {
        this.initImage()
        const userAgent = window.navigator.userAgent
        fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official&userAgent=${userAgent}&clientId=${getClientId()}&url=${getUrlWithoutParam()}&type=${getUrlType()}&recordId=${getUrlId()}`);
    }

    clientCount = 4

    initImage() {
        const list = []
        list.push({
            img: user1,
            info: '星云加速器 Android版用户',
            name: '金灵',
            comment: '一直在用星雲加速器，網絡挺穩定的，一直以為這些軟件都是一樣卡頓，星雲就是不一樣，好軟件，超信賴！也推薦給了朋友了，一直都用這個，好評！ ！ ！ '
        })
        list.push({
            img: user2,
            info: '星云加速器 Windows版用户',
            name: 'Samuel Xu',
            comment: '作者簡直太良心了，速度比同類軟件快上不止一倍啊，用過最好最舒心的加速器，給作者一個大大的讚！'
        })
        list.push({
            img: user3,
            info: '星云加速器 Windows版用户',
            name: '平淡幸福',
            comment: '真的是很棒了 價格能接受 速度超級快的加速器 不出意外會一直用下去的 服務器也很穩定 棒棒噠。'
        })
        list.push({
            img: user4,
            info: '星云加速器 Macos版用户',
            name: '仙鹿',
            comment: ' 我覺得這個軟件非常的好，也不知道為什麼，我覺得他的界面十分的整潔，讓我看的一目了然，不像某些加速器軟件說的挺好，可是用起來卻不行，我覺得以後我是不會卸載這款加速器軟件了'
        })
        list.push({
            img: user5,
            info: '星云加速器 Android版用户',
            name: 'Winters Wang',
            comment: '星云加速器这个软件非常良心，界面操作简便不繁琐一用就会了，链接速度稳定不断线，看视频打游戏都很好用，偶尔上ins找我的男神表白，太爽了。'
        })
        list.push({
            img: user6,
            info: '星云加速器 Macos版用户',
            name: 'Jim Yang',
            comment: '终于找到了，竟然还没有流量限制，每天都离不开google和YouTube，最最最大的福利是tumblr看视频一点都不卡，老司机懂的！'
        })
        list.push({
            img: user7,
            info: '星云加速器 Android版用户',
            name: 'HouBin Chou',
            comment: '简单好用速度快，简直神奇，连接速度极快，玩坦克世界闪电战贼稳，断了还能自动连接,这个加速器帮了大忙了。'
        })
        list.push({
            img: user8,
            info: '星云加速器 Macos版用户',
            name: '梅蒂琪',
            comment: '雪中送炭呀，特别赞，对于我这个需要经常翻墙的人简直就是救星，看1080p的YouTube视频毫无压力，也不限速，非常好。'
        })
        list.push({
            img: user9,
            info: '星云加速器 Windows版用户',
            name: '陆辛燃',
            comment: '相对于榜首的各大软件，价格相对而言算是便宜。下载后就像看见新大陆一样，十分好用，希望可以继续保留住这块净土和小确幸，直到阳光照到你们~'
        })
        list.push({
            img: user10,
            info: '星云加速器 Windows版用户',
            name: 'Asuko Kochōbe',
            comment: '超级好用，连接上基本不掉线，虽然我只是用来玩游戏看看广告，不过点开广告一点开就能看了，不卡，谢谢开发者带来这么好的软件，又买了一个月时间了'
        })
        list.push({
            img: user11,
            info: '星云加速器 Windows版用户',
            name: 'Ztx',
            comment: '超级棒的一个软件，用了几个月了很稳定价格也便宜，如果能多绑定几个设备就好了，只能绑定2个设备不够→_→，2台手机都要用还有台电脑也要使用希望能增加多个设备使用'
        })
        list.push({img: user12, info: '星云加速器 Android版用户', name: 'YiLong Wang', comment: '真nb，速度无敌，价格也合适，真后悔没早用这个加速器'})
        list.push({
            img: user13,
            info: '星云加速器 Android版用户',
            name: '神原遥',
            comment: '一直都很稳定，用了小半年没出过问题，希望能长久，比某些加速器好用的多，价格还比他们便宜，手机电脑双用，非常推荐。'
        })
        list.push({img: user14, info: '星云加速器 Macos版用户', name: 'Super Man', comment: '用了2个多月，真的很稳定，很不错。'})
        list.push({
            img: user15,
            info: '星云加速器 Android版用户',
            name: 'yz long',
            comment: '不错，油管1440p稳稳的，4k有时行有时不行。整体上性价比不错。'
        })
        list.push({
            img: user16,
            info: '星云加速器 Macos版用户',
            name: 'YYUU AANN',
            comment: '我用过最好用的，别的都是试用速度快，充值之后就卡了，唯有星云，始终如一'
        })
        list.push({
            img: user17,
            info: '星云加速器 Macos版用户',
            name: '杨芊芊',
            comment: '花了钱确实可以 追评，没有加速器的真的推荐试试，效果一流，在众多加速器里稳定和流畅绝对算上等的'
        })
        list.push({
            img: user18,
            info: '星云加速器 Android版用户',
            name: 'zhemin wang',
            comment: '挺不错的，刚刚开始使用，加速效果明显，让我没想到的是有这个加速FIFA，效果居然比用游戏加速器加速效果差不多，甚至在某些方面更好，支持一下，希望游戏加速效果能更好😊'
        })
        list.push({
            img: user19,
            info: '星云加速器 Android版用户',
            name: '今晚打老虎',
            comment: '还不错 我都按推荐来链接的 一开始没付费使用了一下感觉速度挺快就开了会员 最重要是什么 是使用国内的软件的时候不用关软件 这个就很好了 不用来回切换 看视频速度很快'
        })
        list.push({
            img: user20,
            info: '星云加速器 Windows版用户',
            name: 'Damion_2333',
            comment: '看看推和p站這個速度完全可以～和其他的加速器比起來確實算便宜的 挺不錯的'
        })
        this.setState({
            avatarList: list
        })
    }

    onYearPurchase() {
        const userAgent = window.navigator.userAgent
        fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_year_purchase_clicked&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        // showToast('请下载客户端，在客户端中购买')
        // showToast('请登陆')
        this.setState({
            showLoginModal: true,
            loginModalTitle: '请登录后购买',
            afterLoginAction: 'YEAR_PAYMENT'
        })
    }

    onHalfYearPurchase() {
        const userAgent = window.navigator.userAgent
        fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_half_year_purchase_clicked&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        // showToast('请下载客户端，在客户端中购买')
        // showToast('请登陆')
        this.setState({
            showLoginModal: true,
            loginModalTitle: '请登录后购买',
            afterLoginAction: 'HALF_YEAR_PAYMENT'
        })
    }

    onSeasonPurchase() {
        const userAgent = window.navigator.userAgent
        fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_season_purchase_clicked&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        // showToast('请下载客户端，在客户端中购买')
        this.setState({
            showLoginModal: true,
            loginModalTitle: '请登录后购买',
            afterLoginAction: 'SEASON_PAYMENT'
        })
        // showToast('请登陆')
    }

    onMonthPurchase() {
        const userAgent = window.navigator.userAgent
        fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_month_purchase_clicked&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        // showToast('请下载客户端，在客户端中购买')
        // showToast('请登陆')
        this.setState({
            showLoginModal: true,
            loginModalTitle: '请登录后购买',
            afterLoginAction: 'MONTH_PAYMENT'
        })
    }

    async onWinDownload() {
        try {
            showLoading()
            const version = await this.getWinLatestVersion()
            const userAgent = window.navigator.userAgent
            fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_win_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
            showToast('开始下载...')
            startDownload(`https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_nebula_win_${version}.zip`)
        } catch (e) {
            showToast('下载失败，请重试')
        } finally {
            hideLoading()
        }
    }

    async onApkDownload() {
        try {
            showLoading()
            const downloadLink = await getAndroidDownloadLink('666666')
            // fetch(downloadLink)
            // const version = await this.getAndroidLatestVersion()
            // const userAgent = window.navigator.userAgent
            // fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_android_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
            showToast('开始下载...')
            startDownload(downloadLink)
            // startDownload(`https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_nebula_release_${version}.apk`)
        } catch (e) {
            showToast('下载失败，请重试')
        } finally {
            hideLoading()
        }
    }

    onIosDownload() {
        // this.setState({
        //     showIosDownloadModal: true
        // })
        // const userAgent = window.navigator.userAgent
        // fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_ios_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        // this.setState({
        //     showLoginModal: true,
        //     loginModalTitle: '请登录后下载ios自签名安装包',
        //     afterLoginAction: 'IOS_DOWNLOAD'
        // })
        this.setState({
            showConfirmModal: true,
            confirmModalTitle: '跳转提示',
            confirmModalContent: 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
        })

        // showToast('请联系管理员为您开通')
    }

    onMacDownload() {
        // try {
        //     showLoading()
        //     const userAgent = window.navigator.userAgent
        //     fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_mac_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        //     showToast('开始下载...')
        //     startDownload('https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_Starlink_VPN.zip')
        // } catch (e) {
        //     showToast('下载失败，请重试')
        // } finally {
        //     hideLoading()
        // }
        this.setState({
            showConfirmModal: true,
            confirmModalTitle: '跳转提示',
            confirmModalContent: 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
        })
    }

    onTelegramClicked() {
        const userAgent = window.navigator.userAgent
        fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_telegram_clicked&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        window.open('https://t.me/StarLinkVPNCustomerServiceGroup', '_blank');
    }

    onTwitterClicked() {
        const userAgent = window.navigator.userAgent
        fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_twitter_clicked&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        window.open('https://twitter.com/NebulaBinary', '_blank');
    }

    onTelegramServiceClicked() {
        const userAgent = window.navigator.userAgent
        fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_telegram_service_clicked&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
        window.open('https://t.me/StarLinkVPNCustomerService', '_blank');
    }

    onScrollTop() {
        const ele = document.getElementById("sl_top_menu_bar");
        ele.scrollIntoView({behavior: 'smooth'})
        this.setState({
            activeMenu: MainMenuType.Home
        })
    }

    onMainMenuClicked(type) {
        this.setState({
            activeMenu: type
        })
        let ele
        if (type === MainMenuType.Download) {
            ele = document.getElementById("sl_download_section");
            ele.scrollIntoView({behavior: 'smooth'})
        } else if (type === MainMenuType.Price) {
            ele = document.getElementById("sl_purchase_section");
            ele.scrollIntoView({behavior: 'smooth'})
        } else if (type === MainMenuType.UserComment) {
            ele = document.getElementById("sl_comments_section'");
            ele.scrollIntoView({behavior: 'smooth'})
        } else if (type === MainMenuType.Login) {
            showToast('尽请期待')
            // this.onShowRegisterModal()
        }

    }

    async onLogin(email, password) {
        showLoading()
        const response = await axios.post(`${serverUrl}webLogin/webEmail`, {email: email, password: password})
        hideLoading()
        if (response && response.data) {
            // const armory = response.data.msg.armory
            // this.swapAgentAddr = response.data.msg.agent
            if (response.data.code === 202) {
                showToast('用户不存在')
                return
            }
            if (response.data.code === 203) {
                showToast('用户名密码不匹配')
                return
            }
            if (response.data.code === 204) {
                showToast('参数错误')
                return
            }
            if (response.data.code === 205) {
                showToast('服务器错误，请重试')
                return
            }

            if (response.data.code === 200) {
                if (this.state.afterLoginAction === 'IOS_DOWNLOAD') {
                    showToast('登录成功,开始下载...')
                    startDownload('https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/nebulavpn_1.1.3.ipa')

                } else if (this.state.afterLoginAction.indexOf('PAYMENT') > -1) {
                    this.setState({
                        showSelectPaymentMethodModal: true
                    })
                }
                if (response.data.sessionId) {
                    localStorage.setItem('sessionId', response.data.sessionId)
                }

                this.setState({
                    showLoginModal: false
                })
                // const sessionId = response.data.sessionInfo.sessionId
                // const email = response.data.userInfo.email
                // localStorage.setItem('sessionId', sessionId)
                // this.setState({
                //     displayType: 'DIVIDEND_FORM',
                //     loginEmail: email,
                //     receiverWalletAddress: response.data.receiverWalletAddress,
                //     referCode: response.data.referCode,
                //     level: response.data.level
                // })
            }
        }
    }

    async makePayment() {
        let planType

        switch (this.state.afterLoginAction) {
            case 'MONTH_PAYMENT':
                planType = 'ST_MONTH'
                break;
            case 'SEASON_PAYMENT':
                planType = 'ST_QUARTER'
                break;
            case 'HALF_YEAR_PAYMENT':
                planType = 'ST_HALFYEAR'
                break;
            case 'YEAR_PAYMENT':
                planType = 'ST_YEAR'
                break;
            default:
                planType = 'ST_MONTH'
                break
        }
    }

    async onLoginCancel() {
        this.setState({
            showLoginModal: false
        })
    }

    async getAndroidLatestVersion() {
        const response = await fetch(`https://payment.private-tunnel.com:3099/version/getAndroidLastestVersion`);
        const {msg} = await response.json();
        return msg
    }

    async getWinLatestVersion() {
        const response = await fetch(`https://payment.private-tunnel.com:3099/version/getWinLatestVersion`);
        const {msg} = await response.json();
        return msg
    }

    // showToast(text) {
    //     let toastBody = document.createElement("div");
    //     toastBody.classList.add('sl-toast');
    //     toastBody.innerText = text
    //     document.body.append(toastBody)
    //     const timeoutID = window.setTimeout(() => {
    //         document.body.removeChild(toastBody)
    //         window.clearTimeout(timeoutID)
    //     }, 4000);
    // }

    onShowRegisterModal() {
        this.setState({
            showRegisterModal: true
        })
    }

    onHideRegisterModal() {
        this.setState({
            showRegisterModal: false
        })
    }

    onUserLogin () {
        this.setState({
            showRegisterModal: true
        })
    }

    setSendVcodeCountDown() {
        this.setState({vcodeCountdownRemain: vcodeCountdown})
        const intervalId = setInterval(() => {
            let countdown = this.state.vcodeCountdownRemain
            if (countdown === 0) {
                clearInterval(intervalId)
            } else {
                countdown--
                this.setState({
                    vcodeCountdownRemain: countdown
                })
            }
        }, 1000)
    }

    async onPaymentMethodConfirmed(method) {
        console.log(method)
        if (method === 'USDTPAY') {
            return
        }
        showLoading()
        let paymentMethod
        switch (method) {
            case 'ALIPAY':
                paymentMethod = 'alipay'
                break
            case 'WECHATPAY':
                paymentMethod = 'wechat_pay'
                break
            case 'CREDITCARD':
                paymentMethod = 'card'
                break
            default:
                paymentMethod = 'alipay'
                break
        }

        let planType

        switch (this.state.afterLoginAction) {
            case 'MONTH_PAYMENT':
                planType = 'ST_MONTH'
                break;
            case 'SEASON_PAYMENT':
                planType = 'ST_QUARTER'
                break;
            case 'HALF_YEAR_PAYMENT':
                planType = 'ST_HALFYEAR'
                break;
            case 'YEAR_PAYMENT':
                planType = 'ST_YEAR'
                break;
            default:
                planType = 'ST_MONTH'
                break
        }
        const sessionId = localStorage.getItem('sessionId')

        if (method === 'WECHATPAY') {
            const response = await axios.post(`${serverUrl}web/getWechatPaymentLink`, {sessionId: sessionId, method: paymentMethod, planType: planType})
            if (response && response.data && response.data.msg && response.data.msg.payUrl) {
                window.open(response.data.msg.payUrl, '_blank');
            }
        } else if (method === 'ALIPAY') {
            const response = await axios.post(`${serverUrl}web/getAliPaymentLink`, {sessionId: sessionId, method: paymentMethod, planType: planType})
            if (response && response.data && response.data.msg && response.data.msg.payUrl) {
                window.open(response.data.msg.payUrl, '_blank');
            }
        } else{
            const response = await axios.post(`${serverUrl}webLogin/getPaymentId`, {sessionId: sessionId, method: paymentMethod, planType: planType})

            if (response && response.data) {
                // const armory = response.data.msg.armory
                // this.swapAgentAddr = response.data.msg.agent
                if (response.data.code !== 200) {
                    showToast('网络出错，请重试')
                    return
                }

                if (response.data.code === 200 && response.data.msg && response.data.msg.paymentId) {
                    window.open(`https://payment.private-tunnel.com:3099/checkoutMac/?paymentId=${response.data.msg.paymentId}`, '_blank');

                }
            }
        }

        hideLoading()
    }
    onPaymentMethodCanceled() {
        this.setState({
            showSelectPaymentMethodModal: false
        })
    }

    closeConfirmModal() {
        this.setState({
            showConfirmModal: false
        })
    }

    confirmRedirect() {
        window.open('https://www.alphacloud.cc', '_blank');
    }

    render() {

        return (
            <div className="sl-main-container">
                <div className={'sl-main-main-col'}>
                    <div className={'sl-content-container'}>
                        <div className={'sl-header'} id={'sl_top_menu_bar'}>
                            <div>
                                <img src={main_logo} className={'sl-main-logo'}/>星云加速器 永不失连
                            </div>

                            <div className={'sl-main-menu'}>
                                <div
                                    className={'sl-main-menu-text' + (this.state.activeMenu === MainMenuType.Home ? ' active' : '')}
                                    onClick={this.onMainMenuClicked.bind(this, MainMenuType.Home)}>首页
                                </div>
                                <div
                                    className={'sl-main-menu-text' + (this.state.activeMenu === MainMenuType.Download ? ' active' : '')}
                                    onClick={this.onMainMenuClicked.bind(this, MainMenuType.Download)}>下载
                                </div>
                                <div
                                    className={'sl-main-menu-text' + (this.state.activeMenu === MainMenuType.Price ? ' active' : '')}
                                    onClick={this.onMainMenuClicked.bind(this, MainMenuType.Price)}>价格
                                </div>
                                <div
                                    className={'sl-main-menu-text' + (this.state.activeMenu === MainMenuType.UserComment ? ' active' : '')}
                                    onClick={this.onMainMenuClicked.bind(this, MainMenuType.UserComment)}>用户评价
                                </div>
                                <div
                                    className={'sl-main-menu-text'}
                                    onClick={this.onUserLogin.bind(this, MainMenuType.UserComment)}>新用户注册
                                </div>
                                {/*<div*/}
                                {/*    className={'sl-main-menu-text' + (this.state.activeMenu === MainMenuType.Login ? ' active' : '')}*/}
                                {/*    onClick={this.onMainMenuClicked.bind(this, MainMenuType.Login)}>登陆/注册*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className={'sl-sub-header'}>新一代网络加速器，保护数据安全，让您畅游网络无后顾之忧！</div>
                        <div className={'sl-main-content-container'}>

                            <div className={'sl-feature-container'}>
                                <div className={'sl-feature-card-row'}>
                                    <div className={'sl-feature-card'}>
                                        <img src={nodeIcon} alt="node-icon" className={'sl-feature-img'}/>
                                        <div className={'sl-feature-text'}>全球多个国家的中转节点</div>
                                    </div>
                                    <div className={'sl-feature-card'}>
                                        <img src={customerServiceIcon} alt="node-icon" className={'sl-feature-img'}/>
                                        <div className={'sl-feature-text'}>快速技术支持，解决疑难杂症</div>
                                    </div>
                                </div>
                                <div className={'sl-feature-card-row'}>
                                    <div className={'sl-feature-card'}>
                                        <img src={encryptIcon} alt="node-icon" className={'sl-feature-img'}/>
                                        <div className={'sl-feature-text'}>双向加密通信，捍卫您的隐私</div>
                                    </div>
                                    <div className={'sl-feature-card'}>
                                        <img src={aiIcon} alt="node-icon" className={'sl-feature-img'}/>
                                        <div className={'sl-feature-text'}>独特算法，突破网络管控</div>
                                    </div>
                                </div>
                                <div className={'sl-feature-card-row'}>
                                    <div className={'sl-feature-card'}>
                                        <img src={satIcon} alt="node-icon" className={'sl-feature-img'}/>
                                        <div className={'sl-feature-text'}>优化线路，低延时大带宽</div>
                                    </div>
                                    <div className={'sl-feature-card'}>
                                        <img src={osIcon} alt="node-icon" className={'sl-feature-img'}/>
                                        <div className={'sl-feature-text'}>支持四大主流操作系统</div>
                                    </div>
                                </div>
                                <div className={'sl-feature-card-row'}>
                                    <div className={'sl-feature-card'}>
                                        <img src={teamIcon} alt="node-icon" className={'sl-feature-img'}/>
                                        <div className={'sl-feature-text'}>前大厂技术团队研发</div>
                                    </div>
                                    <div className={'sl-feature-card'}>
                                        <img src={brandWidthIcon} alt="node-icon" className={'sl-feature-img'}/>
                                        <div className={'sl-feature-text'}>打游戏看电影丝般顺滑</div>
                                    </div>
                                </div>

                            </div>
                            <div className={'sl-phone-img-container'}>
                                <img src={phoneImg} alt="phone" className={'sl-phone-img'}/>
                            </div>
                        </div>
                    </div>
                    <div className={'sl-hr'}/>
                    <div className={'sl-main-download-page'} id={'sl_download_section'}>

                        <div className={'sl-section-title-download'}>立即下载，开始免费使用</div>

                        <div className={'sl-download-container'}>
                            <div className={'sl-download-cell-container'}>
                                <div className={'sl-os-img-container'}>
                                    <img src={osWinIcon} alt="os windows" className={'sl-download-os'}/>
                                </div>
                                <div className={'sl-os-title'}>Windows</div>
                                <div className={'sl-download-btn'} onClick={this.onWinDownload.bind(this)}>下载</div>
                            </div>
                            <div className={'sl-download-cell-container'}>
                                <div className={'sl-os-img-container'}>
                                    <img src={osAndIcon} alt="os windows" className={'sl-download-os'}/>
                                </div>
                                <div className={'sl-os-title'}>Android</div>
                                <div className={'sl-download-btn'} onClick={this.onApkDownload.bind(this)}>下载</div>
                            </div>
                            <div className={'sl-download-cell-container'}>
                                <div className={'sl-os-img-container'}>
                                    <img src={osIosIcon} alt="os windows" className={'sl-download-os'}/>
                                </div>
                                <div className={'sl-os-title'}>Iphone</div>
                                <div className={'sl-download-btn'} onClick={this.onIosDownload.bind(this)}>下载</div>
                                {/*<a className={'sl-sign-link'} href={'howToSign'} target='_blank'>点击进入自签指南</a>*/}
                            </div>
                            <div className={'sl-download-cell-container'}>
                                <div className={'sl-os-img-container'}>
                                    <img src={osMacosIcon} alt="os windows" className={'sl-download-os'}/>
                                </div>
                                <div className={'sl-os-title'}>Mac</div>
                                <div className={'sl-download-btn'} onClick={this.onMacDownload.bind(this)}>下载</div>
                            </div>
                        </div>
                    </div>
                    <div className={'sl-hr'}/>
                    <div className={'sl-main-price-page'} id={'sl_purchase_section'}>
                        <div className={'sl-section-title'}>购买会员</div>
                        <div className={'sl-vip-card-container'}>
                            <div className={'sl-vip-card sl-vip-card-margin'}>
                                {/*<div className={'sl-vip-card-promo'}>*/}
                                {/*    首充特惠*/}
                                {/*</div>*/}
                                {/*<div className={'sl-vip-card-promo-content'}>*/}
                                {/*    首次充值VIP会员月卡，享双倍时间优惠~*/}
                                {/*</div>*/}

                                <div className={'sl-vip-card-title'}>
                                    标准月卡
                                </div>
                                <div className={'sl-vip-card-price'}>
                                    ¥20.0
                                </div>
                                <div className={'sl-vip-duration-container'}>
                                    <div className={'sl-vip-card-price'}>
                                        有效期30天
                                    </div>
                                    {/*<div className={'sl-vip-card-promo-duration'}>*/}
                                    {/*    有效期60天*/}
                                    {/*</div>*/}
                                </div>
                                <div className={'sl-vip-card-client-container'}>
                                    <div className={'sl-vip-card-price'}>客户端数量： {this.clientCount}个</div>
                                    <div className={'sl-vip-card-price'}>端口速率： 无限制</div>
                                </div>

                                <div className={'sl-vip-card-feature'}>
                                    <img className={'sl-vip-card-check'} src={checkCircle} alt="check"/>全球节点分布
                                </div>
                                <div className={'sl-vip-card-feature'}>
                                    <img className={'sl-vip-card-check'} src={checkCircle} alt="check"/>快速客服响应
                                </div>
                                <div className={'sl-vip-card-feature'}>
                                    <img className={'sl-vip-card-check'} src={checkCircle} alt="check"/>全平台客户端
                                </div>
                                <div className={'sl-vip-card-purchase-btn disable-select'}
                                     onClick={this.onMonthPurchase.bind(this)}>购买
                                </div>
                            </div>
                            <div className={'sl-vip-card sl-vip-card-margin'}>
                                <div className={'sl-vip-card-title'}>
                                    标准季卡
                                </div>
                                <div className={'sl-vip-card-price'}>
                                    ¥55.0
                                </div>
                                <div className={'sl-vip-card-price'}>
                                    有效期90天
                                </div>
                                <div className={'sl-vip-card-client-container'}>
                                    <div className={'sl-vip-card-price'}>客户端数量： {this.clientCount}个</div>
                                    <div className={'sl-vip-card-price'}>端口速率： 无限制</div>
                                </div>

                                <div className={'sl-vip-card-feature'}>
                                    <img className={'sl-vip-card-check'} src={checkCircle} alt="check"/>全球节点分布
                                </div>
                                <div className={'sl-vip-card-feature'}>
                                    <img className={'sl-vip-card-check'} src={checkCircle} alt="check"/>快速客服响应
                                </div>
                                <div className={'sl-vip-card-feature'}>
                                    <img className={'sl-vip-card-check'} src={checkCircle} alt="check"/>全平台客户端
                                </div>
                                <div className={'sl-vip-card-purchase-btn disable-select'}
                                     onClick={this.onSeasonPurchase.bind(this)}>购买
                                </div>
                            </div>
                            <div className={'sl-vip-card sl-vip-card-margin'}>
                                <div className={'sl-vip-card-title'}>
                                    标准半年卡
                                </div>
                                <div className={'sl-vip-card-price'}>
                                    ¥95.0
                                </div>
                                <div className={'sl-vip-card-price'}>
                                    有效期180天
                                </div>
                                <div className={'sl-vip-card-client-container'}>
                                    <div className={'sl-vip-card-price'}>客户端数量： {this.clientCount}个</div>
                                    <div className={'sl-vip-card-price'}>端口速率： 无限制</div>
                                </div>

                                <div className={'sl-vip-card-feature'}><img className={'sl-vip-card-check'}
                                                                            src={checkCircle} alt="check"/>全球节点分布
                                </div>
                                <div className={'sl-vip-card-feature'}><img className={'sl-vip-card-check'}
                                                                            src={checkCircle} alt="check"/>快速客服响应
                                </div>
                                <div className={'sl-vip-card-feature'}><img className={'sl-vip-card-check'}
                                                                            src={checkCircle} alt="check"/>全平台客户端
                                </div>
                                <div className={'sl-vip-card-purchase-btn disable-select'}
                                     onClick={this.onHalfYearPurchase.bind(this)}>购买
                                </div>
                            </div>
                            <div className={'sl-vip-card'}>
                                <div className={'sl-vip-card-title'}>
                                    标准年卡
                                </div>
                                <div className={'sl-vip-card-price'}>
                                    ¥165.0
                                </div>
                                <div className={'sl-vip-card-price'}>
                                    有效期365天
                                </div>
                                <div className={'sl-vip-card-client-container'}>
                                    <div className={'sl-vip-card-price'}>客户端数量： {this.clientCount}个</div>
                                    <div className={'sl-vip-card-price'}>端口速率： 无限制</div>
                                </div>

                                <div className={'sl-vip-card-feature'}><img className={'sl-vip-card-check'}
                                                                            src={checkCircle} alt="check"/>全球节点分布
                                </div>
                                <div className={'sl-vip-card-feature'}><img className={'sl-vip-card-check'}
                                                                            src={checkCircle} alt="check"/>快速客服响应
                                </div>
                                <div className={'sl-vip-card-feature'}><img className={'sl-vip-card-check'}
                                                                            src={checkCircle} alt="check"/>全平台客户端
                                </div>
                                <div className={'sl-vip-card-purchase-btn disable-select'}
                                     onClick={this.onYearPurchase.bind(this)}>购买
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'sl-hr'}/>
                    <div className={'sl-client-comments'} id={'sl_comments_section'}>
                        <div className={'sl-section-title sl-section-title-comments'}>用户评价</div>
                        <div className={'sl-avatar-container'}>
                            <div className={'sl-avatar-comment'}>
                                {this.state.avatarList.length > 0 ? this.state.avatarList[this.state.currentActiveSlideIndex].comment : ''}
                            </div>
                            <Swiper
                                className={'sl-swiper-container'}
                                modules={[Navigation, Pagination, Autoplay]}
                                autoplay={true}
                                spaceBetween={0}
                                slidesPerView={5}
                                centeredSlides={true}
                                loop={true}
                                navigation
                                onSlideChange={
                                    (items) => {
                                        this.setState({currentActiveSlideIndex: items.activeIndex % 20})
                                    }

                                }
                                onSwiper={(swiper) => console.log(swiper)}>
                                {
                                    this.state.avatarList.map(function (item, i) {
                                        return <SwiperSlide key={'swiper-' + i}>
                                            <div className={'sl-user-comment-slide-container'}>
                                                <img src={item.img} alt="user"/>
                                                <div className={'sl-avatar-name'}>{item.name}</div>
                                                <div className={'sl-avatar-text'}>{item.info}</div>
                                            </div>
                                        </SwiperSlide>
                                    }.bind(this))
                                }
                            </Swiper>
                        </div>
                    </div>
                    <div className={'sl-hr'}/>
                    <div className={'sl-customer-support'}>
                        <div className={'sl-section-title'}>售后支持</div>
                        <div className={'sl-section-social-container'}>
                            <div className={'sl-customer-support-social-container'}>
                                <img src={telegramIcon} className={'sl-customer-support-social-icon'}
                                     alt={'telegram icon'}/>
                                <div className={'sl-customer-support-social-text'}
                                     onClick={this.onTelegramClicked.bind(this)}>加入Telegram售后支持群
                                </div>
                            </div>
                            <div className={'sl-customer-support-social-container'}>
                                <img src={telegramIcon} className={'sl-customer-support-social-icon'}
                                     alt={'telegram icon'}/>
                                <div className={'sl-customer-support-social-text'}
                                     onClick={this.onTelegramServiceClicked.bind(this)}>联系Telegram客服
                                </div>
                            </div>
                            {/*<div className={'sl-customer-support-social-container'}>*/}
                            {/*    <img src={telegramIcon} className={'sl-customer-support-social-icon'}*/}
                            {/*         alt={'telegram icon'}/>*/}
                            {/*    <div className={'sl-customer-support-social-text'}*/}
                            {/*         onClick={this.onTelegramServiceClicked.bind(this)}>订阅我们的推特*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={'sl-customer-support-social-container'}>
                                <img src={twitterIcon} className={'sl-customer-support-social-icon'} alt={'telegram icon'}/>
                                <div className={'sl-customer-support-social-text'} onClick={this.onTwitterClicked.bind(this)}>订阅我们的推特(Twitter)</div>
                            </div>
                        </div>
                    </div>
                    <div className={'sl-hr'}/>
                    <div className={'sl-footer-section'}>
                        <div className={'sl-footer-container'}>
                            <div className={'sl-footer-title'}><img className={'sl-app-logo-img'} src={appLogo}
                                                                    alt="app logo"/>星云加速器
                            </div>
                            <div className={'sl-footer-download-container'}>
                                <div className={'sl-footer-download-text'} onClick={this.onWinDownload.bind(this)}><img
                                    className={'sl-footer-download-os-icon'} src={osWinIconFooter} alt=""/>Windows版本下载
                                </div>
                                <div className={'sl-footer-download-text'} onClick={this.onApkDownload.bind(this)}><img
                                    className={'sl-footer-download-os-icon'} src={osAndIconFooter} alt=""/>Android版本下载
                                </div>
                                <div className={'sl-footer-download-text'} onClick={this.onIosDownload.bind(this)}><img
                                    className={'sl-footer-download-os-icon'} src={osIosIconFooter} alt=""/>IOS版本下载
                                </div>
                                <div className={'sl-footer-download-text'} onClick={this.onMacDownload.bind(this)}><img
                                    className={'sl-footer-download-os-icon'} src={osIosIconFooter} alt=""/>MacOS版本下载
                                </div>
                            </div>
                            <div className={'sl-mb-footer-company margin'}>Powered by Argocloud Inc.</div>
                            <div className={'sl-mb-footer-company'}>Company Address：543 Fillmore St, DENVER, CO, 80206,
                                USA
                            </div>
                            <div className={'sl-footer-copy-right'}>版权所有 © {moment().format('YYYY')} 星云加速器 团队</div>
                        </div>
                    </div>
                </div>
                <div className={'sl-scroll-top-btn-container'}>
                    <img className={'sl-scroll-top-img'} src={arrowLeft} alt="arrow up"
                         onClick={this.onScrollTop.bind(this)}/>
                </div>
                {
                    this.state.showRegisterModal ?
                        <RegisterModal onHideRegisterModal={this.onHideRegisterModal.bind(this)}
                                       onShowToast={showToast.bind(this)}
                                       vcodeCountdownRemain={this.state.vcodeCountdownRemain}
                                       setSendVcodeCountdown={this.setSendVcodeCountDown.bind(this)}/> : <></>
                }

                {
                    this.state.showIosDownloadModal ?
                        <IosDownloadModal/> : <></>
                }

                {
                    this.state.showLoginModal === true ?
                    <LoginModal onLogin={this.onLogin.bind(this)} onLoginCancel={this.onLoginCancel.bind(this)} loginModalTitle={this.state.loginModalTitle}/> : <></>
                }
                {/*<SelectPaymentMethodModal onConfirm={this.onPaymentMethodConfirmed.bind(this)} onCancel={this.onPaymentMethodCanceled.bind(this)} />*/}
                {
                    this.state.showSelectPaymentMethodModal === true ?
                        <SelectPaymentMethodModal onConfirm={this.onPaymentMethodConfirmed.bind(this)} onCancel={this.onPaymentMethodCanceled.bind(this)}/> : <></>
                }
                {
                    this.state.showConfirmModal === true ?
                        <ConfirmModal onCloseModal={this.closeConfirmModal.bind(this)} confirm={this.confirmRedirect} modalTitle={this.state.confirmModalTitle} modalContent={this.state.confirmModalContent}/> : <></>
                }
            </div>
        );
    }

}


export default Desktop;