import './ReferRegisterMB.css'
import React, {Component} from "react";
import osWinIcon from "../images/os_win.svg";
import osAndIcon from "../images/os_and.svg";
import osIosIcon from "../images/os_ios.svg";
import osMacosIcon from "../images/os_macos.svg";
import {
    getClientId,
    getUrlId,
    getUrlParamByName,
    getUrlType,
    hideLoading,
    showLoading,
    showToast,
    startDownload
} from "../utils/methods";
import RegisterModal from "../components/RegisterModal";
import main_logo from "../images/sl_logo.svg";
import {vcodeCountdown} from "../utils/constants";
import ConfirmModal from "../components/ConfirmModal";


const moment = require("moment");


class ReferRegisterMB extends Component {
    code
    downloadType

    constructor(props) {
        super(props);
        this.state = {
            showRegisterModal: false,
            vcodeCountdownRemain: 0,
            showConfirmModal: false,
            confirmModalTitle: '',
            confirmModalContent: ''
        }
        this.code = getUrlParamByName('code')
        console.log(this.code)
    }

    componentDidMount() {
    }

    async onWinDownload() {
        if (this.checkIfRegistered()) {
            await this.downloadWin()
        } else {
            this.downloadType = 'WIN'
            this.setState({
                modalTitle: '请注册后下载Windows安装包',
                showRegisterModal: true
            })
        }

    }

    async downloadWin() {
        try {
            showLoading()
            const version = await this.getWinLatestVersion()
            const userAgent = window.navigator.userAgent
            fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_win_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
            showToast('开始下载')
            startDownload(`https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_nebula_win_${version}.zip`)
        } catch (e) {
            showToast('下载失败，请重试')
        } finally {
            hideLoading()
        }
    }

    async onApkReferDownload() {
        if (this.checkIfRegistered()) {
            await this.downloadAndroid()
        } else {
            this.downloadType = 'ANDROID'
            this.setState({
                modalTitle: '请注册后下载Android安装包',
                showRegisterModal: true
            })
        }
    }

    onIosDownload() {

        // if (this.checkIfRegistered()) {
        //     this.downloadIOS()
        // } else {
        //     this.downloadType = 'IOS'
        //     this.setState({
        //         modalTitle: '请注册后下载IOS安装包',
        //         showRegisterModal: true
        //     })
        // }

        this.setState({
            showConfirmModal: true,
            confirmModalTitle: '跳转提示',
            confirmModalContent: 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
        })
    }

    onMacDownload() {

        // if (this.checkIfRegistered()) {
        //     this.downloadMac()
        // } else {
        //     this.downloadType = 'MAC'
        //     this.setState({
        //         modalTitle: '请注册后下载Mac安装包',
        //         showRegisterModal: true
        //     })
        // }

        this.setState({
            showConfirmModal: true,
            confirmModalTitle: '跳转提示',
            confirmModalContent: 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
        })
    }

    onHideRegisterModal() {
        this.setState({
            showRegisterModal: false
        })
    }

    showToast() {

    }

    setSendVcodeCountDown() {
        this.setState({vcodeCountdownRemain: vcodeCountdown})
        const intervalId = setInterval(() => {
            let countdown = this.state.vcodeCountdownRemain
            if (countdown === 0) {
                clearInterval(intervalId)
            } else {
                countdown--
                this.setState({
                    vcodeCountdownRemain: countdown
                })
            }
        }, 1000)
    }

    checkIfRegistered() {
        const isRegistered = localStorage.getItem('isRegistered')
        return isRegistered === 'true';
    }

    async afterRegister() {
        localStorage.setItem('isRegistered', 'true')
        if (this.downloadType === 'ANDROID') {
            await this.downloadAndroid()
        } else if (this.downloadType === 'IOS') {
            this.downloadIOS()
        } else if (this.downloadType === 'MAC') {
            this.downloadMac()
        } else if (this.downloadType === 'WIN') {
            await this.downloadWin()
        }
    }

    async downloadIOS() {
        showToast('开始下载')
        startDownload('https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/nebulavpn_1.1.3.ipa')
        this.setState({
            showLoginModal: false
        })
    }

    async downloadMac() {
        try {
            showLoading()
            const userAgent = window.navigator.userAgent
            fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_mac_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
            showToast('开始下载')
            startDownload('https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_Starlink_VPN.zip')
        } catch (e) {
            showToast('下载失败，请重试')
        } finally {
            hideLoading()
        }
    }

    async downloadAndroid() {
        try {
            showLoading()
            const version = await this.getAndroidLatestVersion()
            const userAgent = window.navigator.userAgent
            fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_android_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
            showToast('开始下载')
            startDownload(`https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_nebula_release_${version}.apk`)
        } catch (e) {
            showToast('下载失败，请重试')
        } finally {
            hideLoading()
        }
    }

    async getAndroidLatestVersion() {
        const response = await fetch(`https://payment.private-tunnel.com:3099/version/getAndroidLastestVersion`);
        const {msg} = await response.json();
        return msg
    }

    async getWinLatestVersion() {
        const response = await fetch(`https://payment.private-tunnel.com:3099/version/getWinLatestVersion`);
        const {msg} = await response.json();
        return msg
    }

    closeConfirmModal() {
        this.setState({
            showConfirmModal: false
        })
    }

    confirmRedirect() {
        window.open('https://www.alphacloud.cc', '_blank');
    }

    isModalOpened() {
        return this.state.showRegisterModal || this.state.showConfirmModal
    }

    render() {
        return (
            <div className="sl-main-container">

                <div className={'sl-mb-main-content-container' + (this.isModalOpened() ? ' blur-content' : '')} id={'sl_download_section'}>
                    <div className={'sl-mb-download-container'}>
                        <div className={'sl-mb-refer-register-main-logo-container'}>
                            <img src={main_logo} className={'sl-mb-main-logo'}
                                 alt={'main logo'}/>
                            星云加速器
                        </div>
                        <div className={'sl-mb-download-title'}>立即下载，开始免费使用</div>
                        <div className={'sl-mb-download-row-container'}>
                            <div className={'sl-mb-download-cell-container'}>
                                <div className={'sl-mb-os-img-container'}>
                                    <img src={osWinIcon} alt="os windows" className={'sl-mb-download-os'}/>
                                </div>
                                <div className={'sl-mb-os-title'}>Windows</div>
                                <div className={'sl-mb-download-btn'}
                                     onTouchStart={this.onWinDownload.bind(this)}>下载
                                </div>
                            </div>
                            <div className={'sl-mb-download-cell-container-right'}>
                                <div className={'sl-mb-os-img-container'}>
                                    <img src={osAndIcon} alt="os windows" className={'sl-mb-download-os'}/>
                                </div>
                                <div className={'sl-mb-os-title'}>Android</div>
                                <div className={'sl-mb-download-btn'}
                                     onTouchStart={this.onApkReferDownload.bind(this)}>下载
                                </div>
                            </div>
                        </div>
                        <div className={'sl-mb-download-row-container'}>
                            <div className={'sl-mb-download-cell-container'}>
                                <div className={'sl-mb-os-img-container'}>
                                    <img src={osIosIcon} alt="os windows" className={'sl-mb-download-os'}/>
                                </div>
                                <div className={'sl-mb-os-title'}>Iphone</div>
                                <div className={'sl-mb-download-btn'}
                                     onTouchStart={this.onIosDownload.bind(this)}>下载
                                </div>
                            </div>
                            <div className={'sl-mb-download-cell-container-right'}>
                                <div className={'sl-mb-os-img-container'}>
                                    <img src={osMacosIcon} alt="os windows" className={'sl-mb-download-os'}/>
                                </div>
                                <div className={'sl-mb-os-title'}>Mac</div>
                                <div className={'sl-mb-download-btn'}
                                     onTouchStart={this.onMacDownload.bind(this)}>下载
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showRegisterModal ?
                        <RegisterModal onHideRegisterModal={this.onHideRegisterModal.bind(this)}
                                       onShowToast={this.showToast.bind(this)}
                                       vcodeCountdownRemain={this.state.vcodeCountdownRemain}
                                       setSendVcodeCountdown={this.setSendVcodeCountDown.bind(this)}
                                       referCode={this.code}
                                       afterRegister={this.afterRegister.bind(this)}
                                       modalTitle={this.state.modalTitle}/> : <></>
                }
                {
                    this.state.showConfirmModal === true ?
                        <ConfirmModal onCloseModal={this.closeConfirmModal.bind(this)} confirm={this.confirmRedirect} modalTitle={this.state.confirmModalTitle} modalContent={this.state.confirmModalContent}/> : <></>
                }
            </div>
        );
    }

}


export default ReferRegisterMB;