import React, {Component} from "react";
import './WithdrawDividendModal.css';

import {showToast, verifyEmail, showLoading, hideLoading} from "../utils/methods"
import axios from "axios";
import {minWithdrawDividend, serverUrl} from "../utils/constants";

class WithdrawDividendModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vcode: ''
        }
    }


    // async onConfirmUpdateWalletAddress() {
    //     if (!this.state.walletAddress) {
    //         showToast('请输入钱包地址')
    //         return
    //     }
    //
    //
    //     if(!this.state.vcode) {
    //         showToast('请输入验证码')
    //         return
    //     }
    //
    //     const sessionId = localStorage.getItem('sessionId')
    //     const response = await axios.post(`${serverUrl}web/configWalletAddress`, {sessionId: sessionId, vcode: this.state.vcode, walletAddress: this.state.walletAddress})
    //     if (response && response.data) {
    //         if (response.data.code === 203) {
    //             showToast('验证码不存在')
    //             return
    //         }
    //         if (response.data.code === 204) {
    //             showToast('验证码过期')
    //             return
    //         }
    //         if (response.data.code === 205) {
    //             showToast('验证失效')
    //             return
    //         }
    //         if (response.data.code === 200) {
    //             showToast('钱包设置成功')
    //
    //             this.props.updateReceiverWalletAddress(response.data.walletAddress)
    //             this.props.onCancelConfigWalletModal()
    //         }
    //     }
    //
    // }

    async onSendVCode () {
        if (this.props.vcodeCountdownRemain > 0) {
            return
        }
        showLoading()
        const sessionId = localStorage.getItem('sessionId')
        const response = await axios.post(`${serverUrl}web/sendWithdrawDividendVcode`, {sessionId: sessionId})

        hideLoading()
        if (response && response.data) {
            // const armory = response.data.msg.armory
            // this.swapAgentAddr = response.data.msg.agent
            if (response.data.code === 200) {

                this.props.setSendVcodeCountdown()
                showToast('验证码已发出。如果没有收到邮件，请检查垃圾邮件箱。')
            }
        }
    }

    async onConfirmWithdrawDividend() {
        if (!this.state.vcode) {
            showToast('请输入验证码')
        }

        showLoading()
        const sessionId = localStorage.getItem('sessionId')
        const response = await axios.post(`${serverUrl}web/withdrawDividend`, {sessionId: sessionId, vcode: this.state.vcode})

        hideLoading()
        if (response && response.data) {
            // const armory = response.data.msg.armory
            // this.swapAgentAddr = response.data.msg.agent
            if (response.data.code === 201 || response.data.code === 202) {
                showToast('登录失效')
                return
            }
            if (response.data.code === 203) {
                showToast('无福利码权限')
                return
            }
            if (response.data.code === 204) {
                showToast('验证码不存在')
                return
            }
            if (response.data.code === 205) {
                showToast('验证码已失效')
                return
            }
            if (response.data.code === 206) {
                showToast('验证码过期')
                return
            }
            if (response.data.code === 207) {
                showToast(`最小提现额度：USDT ${response.data.minWithdrawDividend}`)
                return
            }
            if (response.data.code === 208) {
                showToast('请设置收款钱包地址')
                return
            }
            if (response.data.code === 209) {
                showToast('提现失败请联系管理员')
                return
            }
            if (response.data.code === 200) {
                showToast('提现成功，请等待区块确认。')
                this.props.onCloseWithdrawDividendModal()
                this.props.refreshData()
            }
        }


    }

    render() {
        return <div className={'sl-config-wallet-modal-bg'}>
            <div className={'sl-login-modal'}>
                <div className={'sl-login-modal-container'}>
                    <div className={'sl-login-modal-title'}>分红提现</div>

                    <div className={'sl-withdraw-dividend-modal-content-bold'}>此操作不可逆，请确保您的提现地址为合法的币安智能链BSC钱包地址。</div>
                    <div className={'sl-withdraw-dividend-modal-content'}>提现金额： USDT {Math.floor(this.props.dividendAmount / 7.2 * 100)/100}</div>
                    <div className={'sl-withdraw-dividend-modal-content'}>提现地址： {this.props.walletAddress}</div>
                    <div className={'sl-login-email-field-container'}>
                        <input className={'sl-login-input-email'} type="text" placeholder={'请在此输入邮箱验证码'}
                               value={this.state.vcode}
                               onChange={
                                   (e) => {
                                       this.setState({
                                           vcode: e.currentTarget.value
                                       })
                                   }}/>
                        <div className={'sl-login-send-vcode-btn disable-select'} onClick={this.onSendVCode.bind(this)}>{this.props.vcodeCountdownRemain > 0 ? `等待${this.props.vcodeCountdownRemain}秒` : '发送验证码'}</div>
                    </div>

                    <div className={'sl-login-btn-container-login-modal'}>
                        <div className={'sl-login-btn-cancel-login-modal disable-select'}
                             onClick={this.props.onCloseWithdrawDividendModal}>取消
                        </div>
                        <div className={'sl-login-btn-confirm-login-modal disable-select'}
                             onClick={this.onConfirmWithdrawDividend.bind(this)}>确认
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default WithdrawDividendModal