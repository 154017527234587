import React, {Component} from "react";
import './LoginModal.css';

import {showToast, verifyEmail} from "../utils/methods"

class LoginModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            vcode: '',
            password: '',
            confirmPassword: ''
        }
    }


    onLoginConfirm() {
        if (!verifyEmail(this.state.email)) {
            showToast('请输入正确的邮箱')
            return
        }


        if(!this.state.password) {
            showToast('请输入密码')
            return
        }

        this.props.onLogin(this.state.email, this.state.password)

    }

    onLoginCancel () {
        this.props.onLoginCancel()
    }

    onSendVCode () {

    }

    render() {
        return <div className={'sl-login-modal-bg'}>
            <div className={'sl-login-modal'}>
                <div className={'sl-login-modal-container'}>
                    <div className={'sl-login-modal-title'}>{this.props.loginModalTitle || '用户登录'}</div>
                    <div className={'sl-login-modal-field-label'}>邮箱</div>
                    <div className={'sl-login-email-field-container'}>
                        <input className={'sl-login-input-email'} type="email" placeholder={'请在此输入您的邮箱'}
                               value={this.state.email}
                               onChange={
                                   (e) => {
                                       this.setState({
                                           email: e.currentTarget.value
                                       })
                                   }}/>
                    </div>
                    <div className={'sl-login-modal-field-label'}>密码</div>
                    <input className={'sl-login-input'} value={this.state.password} type="password" placeholder={'请在此输入密码'} onChange={
                        (e) => {
                            this.setState({
                                password: e.currentTarget.value
                            })
                        }}/>
                    <div className={'sl-login-btn-container-login-modal'}>
                        {
                            this.props.onLoginCancel ? <div className={'sl-login-btn-cancel-login-modal disable-select'}
                                                            onClick={this.onLoginCancel.bind(this)}>取消
                            </div> : <></>
                        }

                        <div className={'sl-login-btn-confirm-login-modal disable-select'}
                             onClick={this.onLoginConfirm.bind(this)}>确认
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default LoginModal