import './DividendPage.css'
import React, {Component} from "react";
import 'swiper/css';
import 'swiper/scss/navigation';

import LoginModal from "../components/LoginModal";
import DividendForm from "../components/DividendForm";
import {serverUrl} from "../utils/constants"
import {showToast, showLoading, hideLoading} from "../utils/methods"
import axios from "axios";

const moment = require("moment");


class DividendPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayType: 'LOGIN',
            loginEmail: '',
            receiverWalletAddress: null,
            referCode: null,
            level: null
        }
    }

    async componentDidMount() {
        if (this.state.displayType !== 'DIVIDEND_FORM') {
            const sessionId = localStorage.getItem('sessionId')
            if (sessionId) {
                await this.verifySessionId(sessionId)
            }
        }

    }

    async onLogin(email, password) {
        showLoading()
        const response = await axios.post(`${serverUrl}web/login`, {email: email, password: password})
        hideLoading()
        if (response && response.data) {
            // const armory = response.data.msg.armory
            // this.swapAgentAddr = response.data.msg.agent
            if (response.data.code === 202) {
                showToast('邮箱和密码不匹配')
                return
            }
            if (response.data.code === 201) {
                showToast('邮箱不存在')
                return
            }
            if (response.data.code === 203) {
                showToast('用户不存在')
                return
            }

            if (response.data.code === 200) {
                const sessionId = response.data.sessionInfo.sessionId
                const email = response.data.userInfo.email
                localStorage.setItem('sessionId', sessionId)
                this.setState({
                    displayType: 'DIVIDEND_FORM',
                    loginEmail: email,
                    receiverWalletAddress: response.data.receiverWalletAddress,
                    referCode: response.data.referCode,
                    level: response.data.level
                })
            }


        }
    }

    async verifySessionId(sessionId) {
        showLoading()
        const response = await axios.post(`${serverUrl}web/verifySession`, {sessionId: sessionId})
        hideLoading()
        if (response && response.data) {
            if (response.data.code === 200) {

                this.setState({
                    displayType: 'DIVIDEND_FORM',
                    loginEmail: response.data.email,
                    receiverWalletAddress: response.data.receiverWalletAddress,
                    referCode: response.data.referCode,
                    level: response.data.level
                })
            }
        }
    }

    render() {

        return (
            <div className={'sl-dividend-page-container'}>
                {this.state.displayType === 'LOGIN' ?
                    <LoginModal onLogin={this.onLogin.bind(this)}/> : <DividendForm loginEmail={this.state.loginEmail}
                                                                                    receiverWalletAddress={this.state.receiverWalletAddress}
                                                                                    referCode={this.state.referCode} level={this.state.level}/>
                }
            </div>
        );
    }

}


export default DividendPage;