import React, {Component} from "react";
import './DividendForm.css';

import {copyTextToClipboard, showToast, uuidv4, verifyEmail} from "../utils/methods"
import axios from "axios";
import {minWithdrawDividend, serverUrl, vcodeCountdown} from "../utils/constants";
import ConfigWalletModal from "./ConfigWalletModal";
import WithdrawDividendModal from "./WithdrawDividendModal";

const moment = require("moment");

class DividendForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dividendList: [],
            referAccountsList: [],
            lowerLevelAgentsList: [],
            showConfigWalletModal: false,
            showWithdrawDividendModal: false,
            receiverWalletAddress: props.receiverWalletAddress,
            currentDisplayForm: 'INVITE_FORM',
            totalAvailableDividend: 0,
            vcodeCountdownRemain: 0
        }
    }

    async componentDidMount() {
        this.getDividendRecord()
        this.getReferAccounts()
        this.getLowerLevelAgent()
    }

    onLoginConfirm() {
        if (!verifyEmail(this.state.email)) {
            showToast('请输入正确的邮箱')
            return
        }


        if (!this.state.password) {
            showToast('请输入密码')
            return
        }

        this.props.onLogin(this.state.email, this.state.password)

    }

    onSendVCode() {

    }

    setSendVcodeCountDown() {
        this.setState({vcodeCountdownRemain: vcodeCountdown})
        const intervalId = setInterval(() => {
            let countdown = this.state.vcodeCountdownRemain
            if (countdown === 0) {
                clearInterval(intervalId)
            } else {
                countdown--
                this.setState({
                    vcodeCountdownRemain: countdown
                })
            }
        }, 1000)
    }

    onWithdrawDividend() {

        if (!this.state.receiverWalletAddress) {
            showToast(`请设置收款钱包地址`)
            return
        }
        if (minWithdrawDividend > this.state.totalAvailableDividend / 7.2) {
            showToast(`最小提现额度：USDT ${minWithdrawDividend}`)
            return
        }
        this.setState({
            showWithdrawDividendModal: true
        })
    }

    async getDividendRecord(isForce = false) {
        if (isForce === false) {
            if (Array.isArray(this.state.dividendList) && this.state.dividendList.length > 0) {
                return
            }
        }
        const sessionId = localStorage.getItem('sessionId')
        const response = await axios.post(`${serverUrl}web/getReferredPaymentRecord`, {sessionId: sessionId})
        // const dividendList = response.data.referredPayments
        this.setState({
            dividendList: response.data.referredPayments
        })
        this.getTotalAvailableDividend(response.data.referredPayments)
    }

    getAmount(item) {
        const toReturn = item.amount * item.dividendPercentage / 100
        return toReturn / 100
    }

    configWallet() {
        this.setState({
            showConfigWalletModal: true
        })
    }

    onCancelConfigWalletModal() {
        this.setState({
            showConfigWalletModal: false
        })
    }

    updateReceiverWalletAddress(walletAddress) {
        this.setState({
            receiverWalletAddress: walletAddress
        })
    }

    async getReferAccounts(isForce = false) {
        if (isForce === false) {
            if (Array.isArray(this.state.referAccountsList) && this.state.referAccountsList.length > 0) {
                return
            }
        }
        const sessionId = localStorage.getItem('sessionId')
        const response = await axios.post(`${serverUrl}web/getReferAccounts`, {sessionId: sessionId})

        const accountsList = response.data.accounts
        this.setState({
            referAccountsList: accountsList
        })
    }

    async getLowerLevelAgent(isForce = false) {
        if (isForce === false) {
            if (Array.isArray(this.state.lowerLevelAgentsList) && this.state.lowerLevelAgentsList.length > 0) {
                return
            }
        }
        const sessionId = localStorage.getItem('sessionId')
        const response = await axios.post(`${serverUrl}web/getLowerLevelAgent`, {sessionId: sessionId})

        this.setState({
            lowerLevelAgentsList: response.data.subAgentList
        })
    }

    copyReferCode() {
        copyTextToClipboard(this.props.referCode)
    }

    onFormTabClicked(formType) {
        this.setState({
            currentDisplayForm: formType
        })
    }

    getTotalAvailableDividend(dividendList) {
        let total = 0
        for (const item of dividendList) {
            if (item.isWithdrawed === false) {
                total += this.getAmount(item)
            }

        }
        this.setState({
            totalAvailableDividend: total
        })
    }

    getFormContent() {
        if (this.state.currentDisplayForm === 'INVITE_FORM') {
            return (<div className={'sl-dividend-form-content-container'}>
                <div className={'sl-dividend-form-content-container-row'}>
                    <div className={'sl-dividend-form-col-header'}>邮箱</div>
                    <div className={'sl-dividend-form-col-header'}>注册时间</div>
                </div>
                {
                    this.state.referAccountsList.length > 0 ?
                        this.state.referAccountsList.map((item) =>
                            <div className={'sl-dividend-form-content-container-row'} key={`key-${uuidv4()}`}>

                                <div className={'sl-dividend-form-col-cell'}>{item.email}</div>
                                <div className={'sl-dividend-form-col-cell'}>{item.registerTimeString}</div>
                            </div>
                        ) :
                        <div className={'sl-dividend-form-content-container-row'}>
                            <div className={'sl-dividend-form-col-cell-empty-text'}>无内容</div>
                        </div>
                }
            </div>)
        }
        if (this.state.currentDisplayForm === 'DIVIDEND_FORM') {
            return (<div className={'sl-dividend-form-content-container'}>
                <div className={'sl-dividend-form-content-container-row'}>
                    <div className={'sl-dividend-form-col-header'}>邮箱</div>
                    <div className={'sl-dividend-form-col-header'}>额度</div>
                    <div className={'sl-dividend-form-col-header'}>时间</div>
                    <div className={'sl-dividend-form-col-header'}>提现</div>
                </div>
                {
                    this.state.dividendList.length > 0 ?
                        this.state.dividendList.map((item) =>
                            <div className={'sl-dividend-form-content-container-row'} key={`key-${uuidv4()}`}>

                                <div className={'sl-dividend-form-col-cell'}>{item.email}</div>
                                <div className={'sl-dividend-form-col-cell'}>{this.getAmount(item)}</div>
                                <div
                                    className={'sl-dividend-form-col-cell'}>{moment(parseInt(item.paymentIntentTimestamp) * 1000).format('YYYY-MM-DD HH:mm')}</div>
                                <div
                                    className={'sl-dividend-form-col-cell'}>{item.isWithdrawed === true ? '是' : '否'}</div>
                            </div>
                        ) :
                        <div className={'sl-dividend-form-content-container-row'}>
                            <div className={'sl-dividend-form-col-cell-empty-text'}>无内容</div>
                        </div>
                }
            </div>)
        }

        if (this.state.currentDisplayForm === 'LOWER_AGENT_FORM') {
            return (<div className={'sl-dividend-form-content-container'}>
                <div className={'sl-dividend-form-content-container-row'}>
                    <div className={'sl-dividend-form-col-header'}>邮箱</div>
                    <div className={'sl-dividend-form-col-header'}>级别</div>
                </div>
                {
                    this.state.lowerLevelAgentsList.length > 0 ?
                        this.state.lowerLevelAgentsList.map((item) =>
                            <div className={'sl-dividend-form-content-container-row'} key={`key-${uuidv4()}`}>

                                <div className={'sl-dividend-form-col-cell'}>{item.email}</div>
                                <div className={'sl-dividend-form-col-cell'}>{item.level}</div>
                            </div>
                        ) :
                        <div className={'sl-dividend-form-content-container-row'}>
                            <div className={'sl-dividend-form-col-cell-empty-text'}>无内容</div>
                        </div>
                }
            </div>)
        }

    }

    closeWithdrawDividendModal() {
        this.setState({
            showWithdrawDividendModal: false
        })
    }

    refreshData() {
        this.getDividendRecord(true)
        this.getReferAccounts(true)
        this.getLowerLevelAgent(true)
    }

    render() {
        return <div className={'sl-dividend-form-container'}>
            <div className={'sl-dividend-form-tool-bar-container'}>
                {this.props.loginEmail}
                <div className={'sl-dividend-form-tool-bar-btn-container'}>
                    <div className={'sl-dividend-form-tool-bar-btn disable-select'}
                         onClick={this.configWallet.bind(this)}>设置钱包
                    </div>
                    <div className={'sl-dividend-form-tool-bar-btn disable-select'}
                         onClick={this.onWithdrawDividend.bind(this)}>提现
                    </div>
                </div>

            </div>
            <div className={'sl-dividend-form-tool-bar-container'}>
                收款钱包：{this.state.receiverWalletAddress || '无'}
            </div>
            <div className={'sl-dividend-form-refer-code-bar-container'}>
                我的福利码：{this.props.referCode || '无'}
                <div className={'sl-dividend-form-copy-refer-code-btn disable-select'}
                     onClick={this.copyReferCode.bind(this)}>复制
                </div>
            </div>
            <div className={'sl-dividend-form-refer-code-bar-container'}>
                可提现额：￥{Math.floor(this.state.totalAvailableDividend * 100) /100 || 0} (USDT {Math.floor(this.state.totalAvailableDividend / 7.2 * 100) / 100})
            </div>
            <div className={'sl-dividend-form-refer-code-tab-container'}>
                <div
                    className={'sl-dividend-form-refer-code-tab' + (this.state.currentDisplayForm === 'INVITE_FORM' ? ' activate' : '')}
                    onClick={this.onFormTabClicked.bind(this, 'INVITE_FORM')}>注册列表
                </div>
                <div
                    className={'sl-dividend-form-refer-code-tab' + (this.state.currentDisplayForm === 'DIVIDEND_FORM' ? ' activate' : '')}
                    onClick={this.onFormTabClicked.bind(this, 'DIVIDEND_FORM')}>分红列表
                </div>
                {
                    this.props.level != null && this.props.level < 2 ? <div
                        className={'sl-dividend-form-refer-code-tab' + (this.state.currentDisplayForm === 'LOWER_AGENT_FORM' ? ' activate' : '')}
                        onClick={this.onFormTabClicked.bind(this, 'LOWER_AGENT_FORM')}>下级列表
                    </div> : ''
                }
            </div>
            {this.getFormContent()}
            {
                this.state.showConfigWalletModal === true ?
                    <ConfigWalletModal onCancelConfigWalletModal={this.onCancelConfigWalletModal.bind(this)}
                                       updateReceiverWalletAddress={this.updateReceiverWalletAddress.bind(this)}
                                       vcodeCountdownRemain={this.state.vcodeCountdownRemain}
                                       setSendVcodeCountdown={this.setSendVcodeCountDown.bind(this)}/> :
                    <div/>
            }
            {
                this.state.showWithdrawDividendModal === true ?
                    <WithdrawDividendModal walletAddress={this.state.receiverWalletAddress}
                                           onCloseWithdrawDividendModal={this.closeWithdrawDividendModal.bind(this)}
                                           dividendAmount={this.state.totalAvailableDividend}
                                           vcodeCountdownRemain={this.state.vcodeCountdownRemain}
                                           setSendVcodeCountdown={this.setSendVcodeCountDown.bind(this)}
                                           refreshData={this.refreshData.bind(this)}></WithdrawDividendModal> :
                    <div/>
            }


        </div>

    }
}

export default DividendForm