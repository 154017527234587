import React, {Component} from "react";
import './ConfigWalletModal.css';

import {showToast, verifyEmail, showLoading, hideLoading} from "../utils/methods"
import axios from "axios";
import {serverUrl} from "../utils/constants";

class ConfigWalletModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            walletAddress: '',
            vcode: ''
        }
    }


    async onConfirmUpdateWalletAddress() {
        if (!this.state.walletAddress) {
            showToast('请输入钱包地址')
            return
        }


        if(!this.state.vcode) {
            showToast('请输入验证码')
            return
        }

        const sessionId = localStorage.getItem('sessionId')
        showLoading()
        const response = await axios.post(`${serverUrl}web/configWalletAddress`, {sessionId: sessionId, vcode: this.state.vcode, walletAddress: this.state.walletAddress})
        hideLoading()
        if (response && response.data) {
            if (response.data.code === 203) {
                showToast('验证码不存在')
                return
            }
            if (response.data.code === 204) {
                showToast('验证码过期')
                return
            }
            if (response.data.code === 205) {
                showToast('验证失效')
                return
            }
            if (response.data.code === 200) {
                showToast('钱包设置成功')

                this.props.updateReceiverWalletAddress(response.data.walletAddress)
                this.props.onCancelConfigWalletModal()
            }
        }

    }

    async onSendVCode () {
        if (this.props.vcodeCountdownRemain > 0) {
            return
        }
        const sessionId = localStorage.getItem('sessionId')
        showLoading()
        const response = await axios.post(`${serverUrl}web/sendConfigWalletVcode`, {sessionId: sessionId})
        hideLoading()
        if (response && response.data) {
            // const armory = response.data.msg.armory
            // this.swapAgentAddr = response.data.msg.agent
            if (response.data.code === 200) {
                this.props.setSendVcodeCountdown()
                showToast('验证码已发出。如果没有收到邮件，请检查垃圾邮件箱。')
            }
        }
    }

    render() {
        return <div className={'sl-config-wallet-modal-bg'}>
            <div className={'sl-login-modal'}>
                <div className={'sl-login-modal-container'}>
                    <div className={'sl-login-modal-title'}>设置钱包</div>
                    <div className={'sl-login-modal-field-label'}>钱包地址(币安智能链BSC)</div>
                    <div className={'sl-login-email-field-container'}>
                        <input className={'sl-login-input-email'} type="text" placeholder={'请在此输入收款钱包地址'}
                               value={this.state.walletAddress}
                               onChange={
                                   (e) => {
                                       this.setState({
                                           walletAddress: e.currentTarget.value
                                       })
                                   }}/>
                        {/*<div className={'sl-login-send-vcode-btn disable-select'} onClick={this.onSendVCode.bind(this)}>发送验证码</div>*/}
                    </div>

                    <div className={'sl-login-modal-field-label'}>邮箱验证码</div>
                    <div className={'sl-login-email-field-container'}>
                        <input className={'sl-login-input-email'} type="text" placeholder={'请在此输入邮箱验证码'}
                               value={this.state.vcode}
                               onChange={
                                   (e) => {
                                       this.setState({
                                           vcode: e.currentTarget.value
                                       })
                                   }}/>
                        <div className={'sl-login-send-vcode-btn disable-select'} onClick={this.onSendVCode.bind(this)}>{this.props.vcodeCountdownRemain > 0 ? `等待${this.props.vcodeCountdownRemain}秒` : '发送验证码'}</div>
                    </div>

                    <div className={'sl-login-btn-container-login-modal'}>
                        <div className={'sl-login-btn-cancel-login-modal disable-select'}
                             onClick={this.props.onCancelConfigWalletModal}>取消
                        </div>
                        <div className={'sl-login-btn-confirm-login-modal disable-select'}
                             onClick={this.onConfirmUpdateWalletAddress.bind(this)}>确认
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default ConfigWalletModal