import './ReferRegister.css'
import React, {Component} from "react";
import osWinIcon from "../images/os_win.svg";
import osAndIcon from "../images/os_and.svg";
import osIosIcon from "../images/os_ios.svg";
import osMacosIcon from "../images/os_macos.svg";
import {
	getClientId,
	getUrlId,
	getUrlParamByName,
	getUrlType,
	hideLoading,
	showLoading,
	showToast,
	startDownload
} from "../utils/methods";
import RegisterModal from "../components/RegisterModal";
import main_logo from "../images/sl_logo.svg";
import {vcodeCountdown} from "../utils/constants";
import ConfirmModal from "../components/ConfirmModal";


const moment = require("moment");


class ReferRegister extends Component {
	code
	downloadType

	constructor(props) {
		super(props);
		this.state = {
			showRegisterModal: false,
			vcodeCountdownRemain: 0,
			showConfirmModal: false,
			confirmModalTitle: '',
			confirmModalContent: ''
		}
		this.code = getUrlParamByName('code')
		console.log(this.code)
	}

	componentDidMount() {
	}

	async onWinDownload() {

		if (this.checkIfRegistered()) {
			await this.downloadWin('开始下载')
		} else {
			this.downloadType = 'WIN'
			this.setState({
				modalTitle: '请注册后下载Windows安装包',
				showRegisterModal: true
			})
		}
	}

	async onApkReferDownloadWin() {
		if (this.checkIfRegistered()) {
			await this.downloadAndroid('开始下载')
		} else {
			this.downloadType = 'ANDROID'
			this.setState({
				modalTitle: '请注册后下载Android安装包',
				showRegisterModal: true
			})
		}
	}

	onIosDownload() {
		this.setState({
			showConfirmModal: true,
			confirmModalTitle: '跳转提示',
			confirmModalContent: 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
		})
	}

	onMacDownload() {
		this.setState({
			showConfirmModal: true,
			confirmModalTitle: '跳转提示',
			confirmModalContent: 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
		})
	}

	onHideRegisterModal() {
		this.setState({
			showRegisterModal: false
		})
	}

	showToast() {

	}

	setSendVcodeCountDown() {
		this.setState({vcodeCountdownRemain: vcodeCountdown})
		const intervalId = setInterval(() => {
			let countdown = this.state.vcodeCountdownRemain
			if (countdown === 0) {
				clearInterval(intervalId)
			} else {
				countdown--
				this.setState({
					vcodeCountdownRemain: countdown
				})
			}
		}, 1000)
	}

	checkIfRegistered() {
		const isRegistered = localStorage.getItem('isRegistered')
		return isRegistered === 'true';
	}

	async afterRegister() {
		localStorage.setItem('isRegistered', 'true')
		if (this.downloadType === 'ANDROID') {
			this.downloadAndroid()
		} else if (this.downloadType === 'IOS') {
			this.downloadIOS()
		} else if (this.downloadType === 'MAC') {
			this.downloadMac()
		} else if (this.downloadType === 'WIN') {
			this.downloadWin()
		}
	}

	async getAndroidLatestVersion() {
		const response = await fetch(`https://payment.private-tunnel.com:3099/version/getAndroidLastestVersion`);
		const {msg} = await response.json();
		return msg
	}

	async getWinLatestVersion() {
		const response = await fetch(`https://payment.private-tunnel.com:3099/version/getWinLatestVersion`);
		const {msg} = await response.json();
		return msg
	}

	async downloadWin(toastMsg) {
		try {
			showLoading()
			const version = await this.getWinLatestVersion()
			const userAgent = window.navigator.userAgent
			fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_win_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
			showToast(toastMsg || '开始下载...')
			startDownload(`https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_nebula_win_${version}.zip`)
		} catch (e) {
			showToast('下载失败，请重试')
		} finally {
			hideLoading()
		}
	}

	async downloadIOS(toastMsg) {
		showToast(toastMsg || '注册成功，开始下载...')
		startDownload('https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/nebulavpn_1.1.3.ipa')
	}

	async downloadMac(toastMsg) {
		try {
			showLoading()
			const userAgent = window.navigator.userAgent
			fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_mac_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
			showToast(toastMsg || '开始下载...')
			startDownload('https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_Starlink_VPN.zip')
		} catch (e) {
			showToast('下载失败，请重试')
		} finally {
			hideLoading()
		}
	}

	closeConfirmModal() {
		this.setState({
			showConfirmModal: false
		})
	}

	confirmRedirect() {
		window.open('https://www.alphacloud.cc', '_blank');
	}

	async downloadAndroid(toastMsg) {
		try {
			showLoading()
			const version = await this.getAndroidLatestVersion()
			const userAgent = window.navigator.userAgent
			fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_android_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);

			showToast(toastMsg || '注册成功，开始下载...')


			startDownload(`https://release-app-list.oss-cn-shanghai.aliyuncs.com/stl-apps/apps_nebula_release_${version}.apk`)
		} catch (e) {
			showToast('下载失败，请重试')
		} finally {
			hideLoading()
		}
	}

	isModalOpened() {
		return this.state.showRegisterModal || this.state.showConfirmModal
	}

	render() {
		return (
			<div className="sl-main-container">
				<div className={'sl-main-download-page' + (this.isModalOpened() ? ' blur-content' : '')} id={'sl_download_section'}>

					<div className={'sl-refer-register-main-logo-container'}>
						<img src={main_logo} className={'sl-mb-main-logo'}
						     alt={'main logo'}/>
						星云加速器
					</div>
					<div className={'sl-section-title-download'}>立即下载，开始免费使用</div>

					<div className={'sl-download-container'}>
						<div className={'sl-download-cell-container'}>
							<div className={'sl-os-img-container'}>
								<img src={osWinIcon} alt="os windows" className={'sl-download-os'}/>
							</div>
							<div className={'sl-os-title'}>Windows</div>
							<div className={'sl-download-btn'} onClick={this.onWinDownload.bind(this)}>下载</div>
						</div>
						<div className={'sl-download-cell-container'}>
							<div className={'sl-os-img-container'}>
								<img src={osAndIcon} alt="os windows" className={'sl-download-os'}/>
							</div>
							<div className={'sl-os-title'}>Android</div>
							<div className={'sl-download-btn'} onClick={this.onApkReferDownloadWin.bind(this)}>下载</div>
						</div>
						<div className={'sl-download-cell-container'}>
							<div className={'sl-os-img-container'}>
								<img src={osIosIcon} alt="os windows" className={'sl-download-os'}/>
							</div>
							<div className={'sl-os-title'}>Iphone</div>
							<div className={'sl-download-btn'} onClick={this.onIosDownload.bind(this)}>下载</div>
						</div>
						<div className={'sl-download-cell-container'}>
							<div className={'sl-os-img-container'}>
								<img src={osMacosIcon} alt="os windows" className={'sl-download-os'}/>
							</div>
							<div className={'sl-os-title'}>Mac</div>
							<div className={'sl-download-btn'} onClick={this.onMacDownload.bind(this)}>下载</div>
						</div>
					</div>
				</div>
				{
					this.state.showRegisterModal ?
						<RegisterModal onHideRegisterModal={this.onHideRegisterModal.bind(this)}
						               onShowToast={this.showToast.bind(this)}
						               vcodeCountdownRemain={this.state.vcodeCountdownRemain}
						               setSendVcodeCountdown={this.setSendVcodeCountDown.bind(this)}
						               referCode={this.code}
						               afterRegister={this.afterRegister.bind(this)}
						               modalTitle={this.state.modalTitle}/> : <></>
				}
				{
					this.state.showConfirmModal === true ?
						<ConfirmModal onCloseModal={this.closeConfirmModal.bind(this)} confirm={this.confirmRedirect}
						              modalTitle={this.state.confirmModalTitle}
						              modalContent={this.state.confirmModalContent}/> : <></>
				}
			</div>
		);
	}

}


export default ReferRegister;