import React, {Component} from "react";
import './RegisterModal.css';
import {hideLoading, showLoading, showToast, verifyEmail} from "../utils/methods";
import axios from "axios";
import {serverUrl} from "../utils/constants";

class RegisterModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            vcode: '',
            password: '',
            confirmPassword: '',
            referCode: props.referCode
        }
    }


    async onRegConfirm() {
        if (!verifyEmail(this.state.email)) {
            showToast('请输入正确的邮箱')
            return
        }

        if (!this.state.vcode) {
            showToast('请输入验证码')
            return
        }

        if (!this.state.password) {
            showToast('请输入密码')
            return
        }

        if (!this.state.confirmPassword) {
            showToast('请如确认密码')
            return
        }

        if (this.state.password != this.state.confirmPassword) {
            showToast('两次密码输入不一致')
            return
        }
        showLoading()
        const response = await axios.post(`${serverUrl}web/registerAccount`, {
            email: this.state.email,
            vcode: this.state.vcode,
            password: this.state.password,
            referCode:this.state.referCode
        })
        hideLoading()
        if (response && response.data) {
            if (response.data.code === 200) {
                showToast('注册成功')
                if (this.props.afterRegister) {
                    this.props.afterRegister()
                }
                this.props.onHideRegisterModal()
                return
            }
            if (response.data.code === 202) {
                showToast('验证码错误')
                return
            }
            if (response.data.code === 204) {
                showToast('推荐码不存在')
                return
            }
            if (response.data.code === 205) {
                showToast('邮箱已注册')
                return
            }
            showToast('网络错误，请重试')
        }
    }

    // verifyEmail(email) {
    //     var reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
    //     if (email.value === "") {
    //         return false;
    //     }
    //     return reg.test(email);
    // }

    async onSendVCode() {
        if (this.props.vcodeCountdownRemain > 0) {
            return
        }

        if (!verifyEmail(this.state.email)) {
            showToast('请输入正确的邮箱')
            return
        }

        let clientId = localStorage.getItem('clientId')
        showLoading()
        const response = await axios.post(`${serverUrl}web/sendRegisterVcode`, {
            email: this.state.email,
            clientId: clientId
        })
        hideLoading()
        if (response && response.data) {
            if (response.data.code === 200) {
                this.props.setSendVcodeCountdown()
                showToast('验证码已发出。如果没有收到邮件，请检查垃圾邮件箱。')
                return
            }
            if (response.data.code === 301) {
                showToast('网络错误，请重试')
                return
            }
            if (response.data.code === 203) {
                showToast('请输入正确的邮箱')
                return
            }
            if (response.data.code === 202) {
                showToast('用户已存在，请直接登录')
                return
            }
        }
    }

    render() {
        return <div className={'sl-login-modal-bg'}>
            <div className={'sl-login-modal'}>
                <div className={'sl-login-modal-container'}>
                    {
                        this.props.modalTitle ?
                            <div className={'sl-login-modal-title'}>{this.props.modalTitle}</div>:
                            <div className={'sl-login-modal-title'}>注册您的账号</div>
                    }
                    <div className={'sl-login-modal-field-label'}>邮箱</div>
                    <div className={'sl-login-email-field-container'}>
                        <input className={'sl-login-input-email'} type="email" placeholder={'请在此输入您的邮箱'}
                               value={this.state.email}
                               onChange={
                                   (e) => {
                                       this.setState({
                                           email: e.currentTarget.value
                                       })
                                   }}/>
                        <div className={'sl-login-send-vcode-btn disable-select'}
                             onClick={this.onSendVCode.bind(this)}>{this.props.vcodeCountdownRemain > 0 ? `等待${this.props.vcodeCountdownRemain}秒` : '发送验证码'}</div>
                    </div>
                    <div className={'sl-login-modal-field-label'}>验证码</div>
                    <input className={'sl-login-input'} value={this.state.vcode} type="text"
                           placeholder={'请在此输入验证码'} onChange={
                        (e) => {
                            this.setState({
                                vcode: e.currentTarget.value
                            })
                        }}/>
                    <div className={'sl-login-modal-field-label'}>密码</div>
                    <input className={'sl-login-input'} value={this.state.password} type="password"
                           placeholder={'请在此输入密码'} onChange={
                        (e) => {
                            this.setState({
                                password: e.currentTarget.value
                            })
                        }}/>
                    <div className={'sl-login-modal-field-label'}>确认密码</div>
                    <input className={'sl-login-input'} value={this.state.confirmPassword} type="password"
                           placeholder={'请在此确认密码'} onChange={
                        (e) => {
                            this.setState({
                                confirmPassword: e.currentTarget.value
                            })
                        }}/>
                    {
                        this.state.referCode ? <></>:<><div className={'sl-login-modal-field-label'}>推荐码</div>
                            <input disabled={true} className={'sl-login-input'} value={this.state.referCode} type="text"
                                   placeholder={'请在此输入推荐码'} onChange={
                                (e) => {
                                    this.setState({
                                        referCode: e.currentTarget.value
                                    })
                                }}/></>
                    }

                    <div className={'sl-login-btn-container'}>
                        <div className={'sl-login-btn-cancel disable-select'}
                             onClick={this.props.onHideRegisterModal}>取消
                        </div>
                        <div className={'sl-login-btn-confirm disable-select'}
                             onClick={this.onRegConfirm.bind(this)}>确认
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default RegisterModal