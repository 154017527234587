import React, {Component} from "react";
import './HowToSign.css';
import pic1 from "../images/howToSign/pic1.png";
import pic2 from "../images/howToSign/pic2.png";
import pic3 from "../images/howToSign/pic3.png";
import pic4 from "../images/howToSign/pic4.png";
import pic5 from "../images/howToSign/pic5.png";
import pic6 from "../images/howToSign/pic6.png";
import pic7 from "../images/howToSign/pic7.png";
import pic8 from "../images/howToSign/pic8.png";

class HowToSign extends Component {
    render() {
        return(
            <div className= "sl-sign-container">
                <div className="sl-sign-header">IOS签名指南</div>
                <div className="sl-text">1.从scarlet（猩红）官网下载安装签名工具
                    登录scarlet官网：<a href={'https://usescarlet.com/'} target='_blank'>https://usescarlet.com/</a>
                    在页面底部点击install按钮。</div>
                <img src={pic1} alt="node-icon" className={'sl-sign-img'}/>
                <div className="sl-text">2.下载后会自动进行安装，安装完成后需手动信任证书后打开scarlet应用。
                    信任证书步骤：设置 > 通用 > VPN与设备管理 > 企业级app > 信任 “xxx证书”</div>
                <img src={pic2} alt="node-icon" className={'sl-sign-img'}/>
                <div className="sl-text">3.下载星云加速器 ipa安装包<br></br>
                    4.打开猩红（scarlet）</div>
                <img src={pic3} alt="node-icon" className={'sl-sign-img'}/>
                <div className="sl-text">5.选择底部中间的按钮，进入安装页面</div>
                <img src={pic4} alt="node-icon" className={'sl-sign-img'}/>
                <div className="sl-text">6.点击右上角按钮</div>
                <img src={pic5} alt="node-icon" className={'sl-sign-img'}/>
                <div className="sl-text">7.在弹出的菜单中，选择刚才下载的星云加速器 ipa安装包</div>
                <img src={pic6} alt="node-icon" className={'sl-sign-img'}/>
                <div className="sl-text">8.签名完成后会提示安装，点击“安装“按钮</div>
                <img src={pic7} alt="node-icon" className={'sl-sign-img'}/>
                <div className="sl-text">9.安装完成后即可开始使用</div>
                <img src={pic8} alt="node-icon" className={'sl-sign-img'} style={{paddingBottom: "8em"}}/>
            </div>
        )
    }
}

export default HowToSign;