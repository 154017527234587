import React, {Component} from "react";
import './IosDownloadModal.css';

class IosDownloadModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            vcode: '',
            password: '',
            confirmPassword: ''
        }
    }


    onRegConfirm() {
        if (!this.verifyEmail(this.state.email)) {
            this.props.onShowToast('请输入正确的邮箱')
            return
        }

        if (!this.state.vcode) {
            this.props.onShowToast('请输入验证码')
            return
        }

        if(!this.state.password) {
            this.props.onShowToast('请输入密码')
            return
        }

        if(!this.state.confirmPassword) {
            this.props.onShowToast('请如确认密码')
            return
        }

        if(this.state.password != this.state.confirmPassword) {
            this.props.onShowToast('两次密码输入不一致')
            return
        }

    }

    verifyEmail(email) {
        var reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
        if (email.value === "") {
            return false;
        }
        return reg.test(email);
    }

    onSendVCode () {

    }

    render() {
        return <div className={'sl-login-modal-bg'}>
            <div className={'sl-login-modal'}>
                <div className={'sl-login-modal-container'}>
                    <div className={'sl-login-modal-title'}>选择您的偏好</div>
                    <div className={'sl-login-modal-preference-container'}>
                        <div className></div>
                    </div>
                    {/*<div className={'sl-login-modal-field-label'}>邮箱</div>*/}
                    {/*<div className={'sl-login-email-field-container'}>*/}
                    {/*    <input className={'sl-login-input-email'} type="email" placeholder={'请在此输入您的邮箱'}*/}
                    {/*           value={this.state.email}*/}
                    {/*           onChange={*/}
                    {/*               (e) => {*/}
                    {/*                   this.setState({*/}
                    {/*                       email: e.currentTarget.value*/}
                    {/*                   })*/}
                    {/*               }}/>*/}
                    {/*    <div className={'sl-login-send-vcode-btn disable-select'} onClick={this.onSendVCode.bind(this)}>发送验证码</div>*/}
                    {/*</div>*/}
                    {/*<div className={'sl-login-modal-field-label'}>验证码</div>*/}
                    {/*<input className={'sl-login-input'} value={this.state.vcode} type="text" placeholder={'请在此输入验证码'} onChange={*/}
                    {/*    (e) => {*/}
                    {/*        this.setState({*/}
                    {/*            vcode: e.currentTarget.value*/}
                    {/*        })*/}
                    {/*    }}/>*/}
                    {/*<div className={'sl-login-modal-field-label'}>密码</div>*/}
                    {/*<input className={'sl-login-input'} value={this.state.password} type="password" placeholder={'请在此输入密码'} onChange={*/}
                    {/*    (e) => {*/}
                    {/*        this.setState({*/}
                    {/*            password: e.currentTarget.value*/}
                    {/*        })*/}
                    {/*    }}/>*/}
                    {/*<div className={'sl-login-modal-field-label'}>确认密码</div>*/}
                    {/*<input className={'sl-login-input'} value={this.state.confirmPassword} type="password" placeholder={'请在此确认密码'} onChange={*/}
                    {/*    (e) => {*/}
                    {/*        this.setState({*/}
                    {/*            confirmPassword: e.currentTarget.value*/}
                    {/*        })*/}
                    {/*    }}/>*/}
                    {/*<div className={'sl-login-btn-container'}>*/}
                    {/*    <div className={'sl-login-btn-cancel disable-select'}*/}
                    {/*         onClick={this.props.onHideRegisterModal}>取消*/}
                    {/*    </div>*/}
                    {/*    <div className={'sl-login-btn-confirm disable-select'}*/}
                    {/*         onClick={this.onRegConfirm.bind(this)}>确认*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>

    }
}

export default IosDownloadModal