import './ReferRegister.css'
import React, {Component} from "react";
import osAndIcon from "../images/os_and.svg";
import {
    getUrlParamByName,
    hideLoading,
    showLoading,
    showToast,
    startDownload
} from "../utils/methods";
import main_logo from "../images/sl_logo.svg";



class TkAppDownload extends Component {
    code
    downloadType

    constructor(props) {
        super(props);
        this.state = {
            showRegisterModal: false,
            vcodeCountdownRemain: 0
        }
        this.code = getUrlParamByName('code')
        console.log(this.code)
    }

    componentDidMount() {
    }

    onApkDownload() {
        try {
            showLoading()
            // const userAgent = window.navigator.userAgent
            // fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_mac_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
            showToast('开始下载...')
            startDownload('https://release-app-list.oss-cn-shanghai.aliyuncs.com/tk-apps/apps_nebula_release_8.6.4.apk')
        } catch (e) {
            showToast('下载失败，请重试')
        } finally {
            hideLoading()
        }
    }


    render() {
        return (
            <div className="sl-main-container">
                <div className={'sl-main-download-page'} id={'sl_download_section'}>

                    <div className={'sl-refer-register-main-logo-container'}>
                        <img src={main_logo} className={'sl-mb-main-logo'}
                             alt={'main logo'}/>
                        星云加速器 工作室版
                    </div>
                    <div className={'sl-section-title-download'}>立即下载，开始使用</div>

                    <div className={'sl-download-container'}>

                        <div className={'sl-download-cell-container'}>
                            <div className={'sl-os-img-container'}>
                                <img src={osAndIcon} alt="os windows" className={'sl-download-os'}/>
                            </div>
                            <div className={'sl-os-title'}>Android</div>
                            <div className={'sl-download-btn'} onClick={this.onApkDownload.bind(this)}>下载</div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }

}


export default TkAppDownload;