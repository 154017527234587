import React, {Component} from "react";
import './SelectPaymentMethodModal.css';
import alipay from '../images/payment/alipay.svg'
import wechatpay from '../images/payment/wechatpay.svg'
import creditCard from '../images/payment/credit_card.svg'
import radio_selected from '../images/payment/radio_selected.svg'
import radio_unselected from '../images/payment/radio_unselected.svg'
import axios from "axios";
import {serverUrl} from "../utils/constants";

class SelectPaymentMethodModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: '',
            availablePaymentMethod: []
        }
    }

    async componentDidMount() {
        await this.getAvailablePaymentMethods()
    }

    async getAvailablePaymentMethods() {
        const response = await axios.get(`${serverUrl}web/getAvailablePaymentMethods`)
        if (response && response.data) {
            if (response.data.code === 200 && response.data.msg && Array.isArray(response.data.msg.paymentMethods) && response.data.msg.paymentMethods.length > 0) {
                this.setState({
                    availablePaymentMethod: response.data.msg.paymentMethods
                })
            }

        }
    }

    canShowPaymentMethod(paymentType) {
        return this.state.availablePaymentMethod.includes(paymentType);
    }

    onPaymentMethodClicked(paymentType) {
        this.setState({
            paymentMethod: paymentType
        })
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }

    onConfirm() {
        if (this.props.onConfirm) {
            this.props.onConfirm(this.state.paymentMethod)
        }
    }

    render() {
        return <div className={'sl-login-modal-bg'}>
            <div className={'sl-login-modal'}>
                <div className={'sl-login-modal-container'}>
                    <div className={'sl-select-payment-method-title'}>{'请选择支付方式'}</div>
                    {
                        this.canShowPaymentMethod('alipay') ?
                            <div className={'sl-payment-method-modal-row'}
                                 onClick={this.onPaymentMethodClicked.bind(this, 'ALIPAY')}>
                                <img className={'sl-payment-method-logo'} src={alipay}
                                     alt="alipay"/>
                                <div className={'sl-payment-method-text'}>支付宝</div>
                                {
                                    this.state.paymentMethod === 'ALIPAY' ?
                                        <img className={'sl-payment-method-radio'} src={radio_selected}
                                             alt="selected"/> :
                                        <img className={'sl-payment-method-radio'} src={radio_unselected}
                                             alt="unselected"/>
                                }
                            </div> : <></>
                    }
                    {
                        this.canShowPaymentMethod('wechat_pay') ? <div className={'sl-payment-method-modal-row'}
                                                                       onClick={this.onPaymentMethodClicked.bind(this, 'WECHATPAY')}>
                            <img className={'sl-payment-method-logo'} src={wechatpay}
                                 alt="alipay"/>
                            <div className={'sl-payment-method-text'}>微信支付</div>
                            {
                                this.state.paymentMethod === 'WECHATPAY' ?
                                    <img className={'sl-payment-method-radio'} src={radio_selected}
                                         alt="selected"/> :
                                    <img className={'sl-payment-method-radio'} src={radio_unselected}
                                         alt="unselected"/>
                            }
                        </div> : <></>
                    }
                    {
                        this.canShowPaymentMethod('card') ?
                            <div className={'sl-payment-method-modal-row'}
                                 onClick={this.onPaymentMethodClicked.bind(this, 'CREDITCARD')}>
                                <img className={'sl-payment-method-logo'} src={creditCard}
                                     alt="alipay"/>
                                <div className={'sl-payment-method-text'}>信用卡支付</div>
                                {
                                    this.state.paymentMethod === 'CREDITCARD' ?
                                        <img className={'sl-payment-method-radio'} src={radio_selected}
                                             alt="selected"/> :
                                        <img className={'sl-payment-method-radio'} src={radio_unselected}
                                             alt="unselected"/>
                                }
                            </div> : <></>
                    }
                    {/*<div className={'sl-payment-method-modal-row'}*/}
                    {/*     onClick={this.onPaymentMethodClicked.bind(this, 'USDTPAY')}>*/}
                    {/*    <img className={'sl-payment-method-logo'} src={usdt}*/}
                    {/*         alt="alipay"/>*/}
                    {/*    <div className={'sl-payment-method-text'}>USDT支付</div>*/}
                    {/*    {*/}
                    {/*        this.state.paymentMethod === 'USDTPAY' ?*/}
                    {/*            <img className={'sl-payment-method-radio'} src={radio_selected}*/}
                    {/*                 alt="selected"/> :*/}
                    {/*            <img className={'sl-payment-method-radio'} src={radio_unselected}*/}
                    {/*                 alt="unselected"/>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className={'sl-login-btn-container-login-modal'}>

                        <div className={'sl-login-btn-cancel-login-modal disable-select'}
                             onClick={this.onCancel.bind(this)}>取消
                        </div>

                        <div className={'sl-login-btn-confirm-login-modal disable-select'}
                             onClick={this.onConfirm.bind(this)}>确认
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default SelectPaymentMethodModal